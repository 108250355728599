<script lang="ts" setup>
import { cn } from '@/lib/utils';
import { Label, type LabelProps } from 'radix-vue';
import { useAttrs } from 'vue';
import { useFormField } from './useFormField';

defineOptions({
  inheritAttrs: false,
});
defineProps<LabelProps>();

const { error, formItemId } = useFormField();
const { class: className, ...rest } = useAttrs();
</script>

<template>
  <Label
    :class="
      cn(
        'block text-regular-16 text-left ',
        error && 'text-iq-red',
        className ?? ''
      )
    "
    :for="formItemId"
    v-bind="rest"
  >
    <slot />
  </Label>
</template>
